
import { Vue, Component } from "vue-property-decorator";
import { apiGetAbout, apiSetAbout } from "@/api/setting";
import Editor from '@/components/editor.vue'

@Component({
    components: {
        Editor
    }
})
export default class SettingRecord extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
        about: "", // 版权信息
    };
    /** E Data **/

    /** S Methods **/
    // 初始化表单数据
    async initFormData(): Promise<void> {
        const res: any = await apiGetAbout()
        for (const key in res) {
            this.form[key] = res[key];
        }
    }

    // 重置表单数据
    onResetFrom() {
        this.initFormData();
        this.$message.info("已重置数据");
    }

    // 提交表单
    onSubmitFrom(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement;

        refs.validate(async (valid: boolean): Promise<void> => {
            if (!valid) return;
            const loading = this.$loading({ text: "保存中" });

            await apiSetAbout({
                ...this.form,
            }).finally(() => {
                loading.close();
            });
        });
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.initFormData();
    }
    /** E Life Cycle **/
}
